<template>
  <div class="chapter">
    <main>
      <div class="images">
        <div class="left"> {{ that.$route.query.zmc }} </div>
        <div class="right">
          <!-- 教学视频 -->
          <img src="../../../assets/student/tvideoBtn.png" alt="">
          <span style="cursor:pointer;" @click="goVideo">教学视频</span>
        </div>
      </div>
      <div class="swipe" v-if="isShow">
        <el-carousel :autoplay="false" arrow="never" id="cardShow" ref="dropdownRef">
          <el-carousel-item v-for="item in allList" :key="item">
            <div class="swipeitem" v-for="(ele,index) in item" :key="index" @click="gowordlist(ele)">
              <!-- 根据div设置显示的不同图片 -->
              <div v-if="ele.xj >= 0">
                <!-- 带边框 -->
                <img src="../../../assets/student/borderblock.png" alt="">
                <!-- 不带边框 -->
                <!-- <img v-else src="../../../assets/student/xgnblock.png" alt=""> -->
                <!-- 三颗星星 -->
                <div class="stars">
                  <div v-for="itm in 3" :key="itm">
                    <img v-if="ele.xj >= itm" src="../../../assets/student/brightstar.png" alt="">
                    <img v-else src="../../../assets/student/darkstar.png" alt="">
                  </div>
                </div>
                <span>{{ele.mc.substring(0,9).replace(/[ ]/g,"")}}</span>
              </div>
              <div class="nostarts" v-if="ele.xj < 0">
                <!-- 截取8个，删除空格 -->
                <span>{{ele.mc.substring(0,9).replace(/[ ]/g,"")}}</span>
                <img src="../../../assets/student/lock.png" alt="">
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </main>
    <Header :header_data="header_data" />
    <Footer />
  </div>
</template>
<script>
import Header from "../components/zjfheader.vue";
import Footer from "../components/footer.vue";
import { getCurrentInstance, nextTick, onMounted, reactive, toRefs } from 'vue'
import { getTpKwList, getMyBreakpoint, getEnglishText } from '@/api/student'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus';
export default {
  components: {
    Header,
    Footer,
  },
  setup() {
    const store = useStore()
    const { appContext } = getCurrentInstance();
    // vue 实例
    const that = appContext.config.globalProperties;
    // 面包屑
    // const header_data = [
    //   { title: "图谱英语 — ", path: "newEnglish" },
    //   { title: "新概念英语-第一册 — ", path: "units" },
    //   { title: "unit1", path: "" },
    // ];
    // 数据源
    const data = reactive({
      allList: [],
      isShow: true, // 轮播图dom重新加载
      header_data: []
    })
    // 跳转
    function gowordlist(val) {
      // 星级-1
      if (~~val.xj === -1) {
        getEnglishText({kwid: val.id}).then(res => {
          if(res.success) {
            sessionStorage.setItem('zsdid', res.result.zsdid)
            let params = {
              cjlx: 1,
              ddzt: 1,
              dyid: that.$route.query.dyid,
              xsid: store.state.userInfo.yhid,
              zid: that.$route.query.zid,
              zsdid: sessionStorage.getItem('zsdid')
            }
            getMyBreakpoint(params).then(ele => {
              if (ele.success) {
                if (ele.result) {
                  that.$router.replace({ name: JSON.parse(ele.result.ddxx).step, query: { dyid: that.$route.query.dyid, dymc: that.$route.query.dymc, zmc: that.$route.query.zmc, zid: that.$route.query.zid, kwid: val.id } })
                } else {
                  that.$router.replace({ name: 'wordlearning', query: { dyid: that.$route.query.dyid, dymc: that.$route.query.dymc, zmc: that.$route.query.zmc, zid: that.$route.query.zid, kwid: val.id } })
                }
              } else {
                ElMessage({
                  message: ele.resultDesc,
                  type: 'warning'
                })
              }
            })
          } else {
            ElMessage({
              message: res.resultDesc,
              type: 'warning'
            })
          }
        })
        // 星级非-1
      } else {
        that.$router.replace({ name: 'reviewwordlearning', query: { dyid: that.$route.query.dyid, dymc: that.$route.query.dymc, zmc: that.$route.query.zmc, zid: that.$route.query.zid, kwid: val.id } })
      }
    }
    // 教学视频
    const goVideo = () => {
      that.$router.replace({ name: 'teacherText', query: { dyid: that.$route.query.dyid, dymc: that.$route.query.dymc, zmc: that.$route.query.zmc, zid: that.$route.query.zid }})
    }
    onMounted(() => {
      data.header_data = [
        { title: "图谱英语 — ", path: "newEnglish" },
        { title: that.$route.query.zmc+ ' — ', path: `/units?zid=${that.$route.query.zid}&zmc=${that.$route.query.zmc}` },
        { title: that.$route.query.dymc, path: ''}
      ]
      getTpKwList({
        dyid: ~~that.$route.query.dyid,
        kwid:'',
        xsid: ~~that.$store.state.userInfo.yhid
      }).then(res => {
        data.isShow = false
        let arr = []
        res.result.forEach((ele, index) => {
          if (!arr[parseInt(index / 8)]) {
            arr[parseInt(index / 8)] = []
          }
          arr[parseInt(index / 8)].push(ele)
        })
        data.allList = arr
        nextTick(() => {
          data.isShow = true
        })
      })
    })
    return {
      gowordlist,
      that,
      ...toRefs(data),
      goVideo
    };
  },
};
</script>
<style lang="scss" scoped>
.chapter {
  width: 100%;
  height: 100%;
  background: url("../../../assets/student/mnemonic.png") no-repeat;
  background-size: 100% 100%;
  main {
    width: 84.37%;
    height: 72.81%;
    background: url("../../../assets/student/xgnbanner.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    .images {
      .left {
        width: 22.6vw;
        height: 7.3vw;
        background: url("../../../assets/student/xgntitle.png") no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: -1.5vw;
        left: -0.5vw;
        font-size: 1.7vw;
        color: #fff;
        text-align: center;
        line-height: 6.8vw;
      }
      .right {
        position: absolute;
        right: 2.5vw;
        top: -1vw;
        width: 12.1vw;
        height: 4.335vw;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
        span {
          font-size: 1.5vw;
          color: #fff;
          position: absolute;
          top: 45%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      z-index: 2;
    }
    .swipe {
      width: 84%;
      height: 80%;
      margin: 7.5% 0% 0 10%;
      .swipeitem {
        float: left;
        margin-right: 3.4vw;
        margin-bottom: 1vw;
        width: 14.7vw;
        height: 12vw;
        position: relative;
        text-align: center;
        line-height: 12vw;
        cursor: pointer;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
        span {
          font-size: 2vw;
          color: #fff;
          position: absolute;
          left: 50%;
          top: 60%;
          transform: translate(-50%, -60%);
        }
        .stars {
          width: 80%;
          position: absolute;
          top: 4%;
          left: 10%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          z-index: 1;
          div {
            width: 30%;
          }
          img {
            display: block;
            width: 100%;
          }
          div:first-child {
            transform: rotate(-8deg);
          }
          div:last-child {
            transform: rotate(8deg);
          }
        }
        .nostarts {
          height: 100%;
          background: url("../../../assets/student/darkblock.png") no-repeat;
          background-size: 95% 95%;
          margin: 2.5% 0 0 2.5%;
          span {
            position: absolute;
            top: 25%;
            left: 50%;
            transform: translate(-50%, -50%);
            line-height: 0;
          }
          img {
            display: block;
            width: 5.208vw;
            height: 5.208vw;
            position: absolute;
            top: 65%;
            left: 50%;
            transform: translate(-50%, -60%);
          }
        }
      }
      .swipeitem:nth-child(4) {
        margin-right: 0;
      }
      .swipeitem:nth-child(8) {
        margin-right: 0;
      }
    }
  }
}
</style>
<style lang="scss">
.chapter {
  .el-progress-bar__outer {
    background: #fff !important;
    height: 16px !important;
  }
  .el-progress-bar__inner {
    background: #0972e7;
  }
  .el-carousel--horizontal {
    height: 100%;
  }
  .el-carousel__container {
    height: 100%;
  }
  .el-carousel__indicators {
    bottom: 1vw;
  }
  .el-carousel__indicator {
    .el-carousel__button {
      width: 1.8vw;
      height: 0.6vw;
      border-radius: 6px;
      background: #fff;
      opacity: 1;
    }
  }
  .el-carousel__indicator.is-active {
    .el-carousel__button {
      background: #5990ff;
    }
  }
}
</style>